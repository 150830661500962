
import GoogleRichMarker from '~/assets/utils/google-richmarker';
import MarkerClusterer from '@googlemaps/markerclustererplus';

export default {
    props: {
        properties: Array,
        mapCenter: Array,
        mapFullScreen: false,
    },

    data() {
        return {
            hiddenMarker: null,
            map: null,
            opened_properties: [],
            mapInfoWindow: null,
            markerCluster: null,
            circle: null,
            need_fit_map: true,
            zoom_set: 0,
        }
    },
    computed: {
        properties_cloned() {
            return JSON.parse(JSON.stringify(this.properties));
        }
    },
    watch: {
        '$auth.loggedIn'(state) {
            if (state) {
                this.initMap();
                if (this.map && this.properties.length && this.need_fit_map) {
                    this.need_fit_map = false;
                    this.drawMarkers();
                    this.fitMap();
                }
            }
        },
        properties_cloned: {
            deep: true,
            handler (new_val, old_val) {
                if (!this.map) return;

                if (JSON.stringify(new_val.map(r => r.id)) !== JSON.stringify(old_val.map(r => r.id))) {
                    this.drawMarkers();
                    if (this.properties.length && this.need_fit_map) {
                        this.need_fit_map = false;
                        this.fitMap();
                    }
                } else if (JSON.stringify(new_val.map(r => r.is_favorite)) !== JSON.stringify(old_val.map(r => r.is_favorite))) {
                    this.redrawMarkersContent(new_val.filter((r, i) => r.is_favorite !== old_val[i].is_favorite).map(r => r.id));
                }
            }
        },
        mapCenter() {
            this.setMapCenter();
        },
        map() {
            if (this.map && this.properties.length && this.need_fit_map) {
                this.need_fit_map = false;
                this.drawMarkers();
                this.fitMap();
            }
        },
        opened_properties(newList, oldList) {
            this.$emit('highlightOpenedProperties', this.opened_properties);
        }
    },

    mounted() {
        this.initMap();
    },

    methods: {
        async initMap() {
            if (this.map || !this.$auth.loggedIn) return;

            if (!window.google) {
                this.$nuxt.$once('googleMapApi:init', this.initMap);
                return;
            }

            this.mapInfoWindow = new google.maps.InfoWindow;
            this.mapInfoWindow.addListener('closeclick', () => {
                this.closeInfoWindow();
            });

            let mapCenter = this.mapCenter || this.$settings.defaultMapCenter;

            this.map = new google.maps.Map(document.getElementById('map'), {
                zoom: mapCenter[2],
                maxZoom: 17,
                center: {
                    lat: mapCenter[0],
                    lng: mapCenter[1]
                },
                styles: [{
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        { "color": '#a4ddf5' },
                        { "visibility": "on" }
                    ]
                }],
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                panControl: false,
                navigationControl: false,
                clickableIcons: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_BOTTOM
                },
                streetViewControl: false,
            });

            this.map.addListener('click', () => {
                this.closeInfoWindow();
            });

            this.map.addListener('idle', () => {
                this.checkMarkersInViewport();
                this.checkStateFilterDropNeeded();
            });

            this.markerCluster = new MarkerClusterer(this.map, [], {
                gridSize: 30,
                imagePath: '/markerclusterplus/m'
            });

            google.maps.event.addListener(this.markerCluster, 'clusterclick', cluster => {

                if (this.map.getZoom() === this.map.maxZoom) {

                    let marker = null;
                    let property_ids = [];

                    cluster.getMarkers().forEach(item => {
                        let { property_id } = item;
                        marker = item;
                        property_ids.push(property_id);
                    });

                    this.opened_properties = property_ids;
                    this.mapInfoWindow.set('content', this.$refs['prop_popup'].$el);
                    this.mapInfoWindow.open(this.map, marker);
                }
            });
        },
        drawMarkers() {
            if (!this.map) return;

            this.closeInfoWindow();
            this.markerCluster.clearMarkers();
            if (!this.$auth.loggedIn) return;

            let markers = [];

            this.properties.filter(item => !item.filtered).forEach(item =>  {

                if (item.latitude==0 && item.longitude==0) return;

                let price = this.$options.filters.shortprice(item.price);
                let addClass = item.status === this.$settings.dicts.propertyStatus.sold ? ' sold' : '';
                let marker = new GoogleRichMarker.RichMarker({
                    position: new google.maps.LatLng(item.latitude, item.longitude),
                    draggable: false,
                    shadow: 'none',
                    content: `
                        <div class="container-marker${addClass}">
                            <div class="caret-shadow"></div>
                            <div class="marker-body">
                                <div class="child-marker">
                                    ${price}
                                    ` + (item.is_favorite ? '<i class="fa fa-heart"></i>' : '') + `
                                </div>
                            </div>
                            <div class="caret-body"></div>
                        </div>
                    `
                });

                marker.property_id = item.id;

                marker.addListener('click', event => {
                    this.hiddenMarker?.setVisible(true); // turn on previous active marker
                    event.stopPropagation();
                    this.opened_properties = [marker.property_id];
                    this.mapInfoWindow.set('content', this.$refs['prop_popup'].$el);
                    this.mapInfoWindow.open(this.map, marker);
                    marker.setVisible(false);
                    this.hiddenMarker = marker;
                });

                marker.addListener('mouseover', event => {
                    marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
                });

                markers.push(marker);
            });

            this.markerCluster.addMarkers(markers);

            this.checkMarkersInViewport();
        },
        redrawMarkersContent(ids) {
            if (!this.map || !this.$auth.loggedIn) return;

            let properties_by_id = this.$store.getters.properties_by_id;

            this.markerCluster.getMarkers().forEach(marker => {
                // TODO make function getPropertyContent
                let item = properties_by_id[marker.property_id];

                if (!ids.includes(item.id)) return;

                let price = this.$options.filters.shortprice(item.price);
                let addClass = item.status === this.$settings.dicts.propertyStatus.sold ? ' sold' : '';
                marker.setContent(`
                    <div class="container-marker${addClass}">
                        <div class="caret-shadow"></div>
                        <div class="marker-body">
                            <div class="child-marker">
                                ${price}
                                ` + (item.is_favorite ? '<i class="fa fa-heart"></i>' : '') + `
                            </div>
                        </div>
                        <div class="caret-body"></div>
                    </div>
                `);
            })
        },
        fitMap() {
            if (!this.map.getProjection()) {
                google.maps.event.addListenerOnce(this.map, 'projection_changed', this.fitMap);
                return;
            }

            let {height, width} = this.map.getDiv().getBoundingClientRect();
            if (!(height * width)) {
                setTimeout(this.fitMap, 500);
                return;
            }

            let markers = this.markerCluster.getMarkers();

            if (markers.length > 1) {
                this.markerCluster.fitMapToMarkers();
            } else if (markers.length === 1) {
                this.map.setCenter(markers[0].getPosition());
            } else if (this.mapCenter) {
                this.map.setCenter(new google.maps.LatLng(this.mapCenter[0], this.mapCenter[1]));
                this.map.setZoom(this.mapCenter[2]);
            }
        },
        setMapCenter() {
            if (!this.map) return;
            if (!this.mapCenter) return;

            const lat = this.mapCenter[0];
            const lng = this.mapCenter[1];
            const zoom = this.mapCenter[2] || 8;
            const viewport = this.mapCenter[3];

            this.closeInfoWindow();
            if (viewport) {
                this.map.fitBounds(viewport);
            } else {
                this.map.setCenter(new google.maps.LatLng(lat, lng));
                this.map.setZoom(zoom);
            }

            this.zoom_set = this.map.zoom;
        },
        closeInfoWindow() {
            this.mapInfoWindow?.close();
            this.hiddenMarker?.setVisible(true);
            this.$emit('highlightOpenedProperties', this.opened_properties = []);
        },
        checkMarkersInViewport() {
            let visibleList = [];
            let bounds = this.map.getBounds();

            if (!bounds) return;

            bounds && this.properties.forEach(item => {
                if (bounds.contains(new google.maps.LatLng(item.latitude, item.longitude))) {
                    visibleList.push(item.id);
                }
            })
            this.$emit('updatePropertiesList', visibleList);
        },
        checkStateFilterDropNeeded() {
            if (this.map.zoom <= (this.zoom_set - 3) || this.map.zoom < 5) {
                this.need_fit_map = false;
                this.$emit('zoomOutClear');
            }
        }
    }
}
