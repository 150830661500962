
export default {
    props: ['property_ids', 'componentKey'],

    data() {
        return {
            shown_tab: 0
        }
    },
    computed: {
        properties() {
            return this.property_ids.map(id => this.$store.getters.properties_by_id[id]).filter(Boolean);
        }
    },
    watch: {
        property_ids(new_val) {
            if (new_val.length) this.shown_tab = new_val[0];
        }
    },

    methods: {
        open(property) {
            this.$router.push({
                name: 'property-property_id-property_slug',
                params: {property_id: property.id, property_slug: property.slug}
            });
        },
        showTab(id) {
            this.shown_tab = id;
        },
        toggleFavorite(property_id, add) {
            this.$store.dispatch("toggleFavorite", {property_id, add});
        }
    }
}
