
export default {
    props: {
        hostname: {
            type: String,
            default: ""
        },
        property: Object,
        index: Number,
    },
    data() {
        return {
            cssClasses: {

            }
        }
    },
    computed: {
        first_line_address() {
            return this.property.county ? this.property.county : this.property.city;
        },
        second_line_address() {
            let address = "";
            if (this.property.county)
                address = [this.property.city, this.property.state_code, this.property.zip].filter(Boolean).join(', ');
            else
                address = [this.property.state_code, this.property.zip].filter(Boolean).join(', ');

            return address;
        },
        property_listed_date() {
            const daysPublished = this.property.days_published;
            const isOneDayOld = daysPublished === 1;

            if (!daysPublished) return 'Just listed';

            return `${daysPublished} day${isOneDayOld ? '' : 's'} ago`;
        },
        is_just_listed() {
            return this.property.days_published === 0;
        },
        animation_delay() {
            const overallDelay = Math.random() * 5;
            const firstStarDelay = 0.15;
            const secondStarDelay = 0.3;
            return [`${this.index * overallDelay + secondStarDelay}s`, `${this.index * overallDelay}s`, `${this.index * overallDelay + firstStarDelay}s`];
        }
    },
    methods: {
        toggleFavorite(property_id, add) {
            this.$store.dispatch("toggleFavorite", {property_id, add});
        }
    }
}
