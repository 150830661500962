
export default {
    props: {
        visible: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            _visible: !this.visible ? !this.$auth.loggedIn : this.visible === 'yes',
        }
    },

    watch: {
        visible(newVal, oldVal) {
            if (newVal) this.$data._visible = newVal === 'yes';
        },
        '$auth.loggedIn'(newVal, oldVal) {
            if (!this.visible) {
                this.$data._visible = !newVal;
            }
        }
    },

    methods: {
        btnClick() {
            if (this.$listeners.click) {
                this.$emit('click');
            } else {
                this.$bvModal.show('modal-login')
            }
        }
    }
}
