// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/static_map.jpeg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-page .container-marker{cursor:pointer;display:inline-block;position:relative}.index-page .container-marker.sold{opacity:.7}.index-page .container-marker .caret-shadow{bottom:-4px;box-shadow:1px 1px 4px 0 rgba(0,0,0,.15);height:8px;left:50%;margin-left:-4px;position:absolute;transform:rotate(45deg);width:8px}.index-page .container-marker .marker-body{background-color:#fff;border:1px solid rgba(0,0,0,.2);border-radius:2px;box-shadow:0 2px 4px 0 rgba(0,0,0,.15);color:#222;font-family:Roboto,Helvetica Neue,sans-serif;font-size:15px;font-weight:700;letter-spacing:.2px;line-height:18px;padding:4px 5px 4px 6px;position:relative}.index-page .container-marker .caret-body{background-color:#fff;border-bottom:1px solid rgba(0,0,0,.2);border-right:1px solid rgba(0,0,0,.2);bottom:-4px;height:8px;left:50%;margin-left:-4px;position:absolute;transform:rotate(45deg);width:8px}.index-page .container-marker .fa-heart{color:red}.index-page .map-wrapper .not-logged-in{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;background-size:cover}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
