
export default {
    props: {
        value: Object,
        years_list: Array,
    },
    data() {
        return {
            slider: {
                width: "auto",
                height: 2,
                direction: "horizontal",
                dotSize: 20,
                eventType: "auto",
                lazy: true,
                tooltip: "hover",
                tooltipDir: "bottom",
                processStyle: { "backgroundColor": "#274abb" },
            }
        }
    },
    computed: {
        property_types() {
            return this.$config.property_types
        },
        property_tags() {
            return this.$config.property_tags
        },
        readonly() {
            return !this.$auth.loggedIn;
        }
    },
    methods: {
        clearAllFilters() {
            this.$emit('updateMapCenter');
            this.$emit('filters-clear');
        },
        applyBuyBoxFilter() {
            this.$emit('buy-box-apply');
        },

        clear(elements) {
            if (typeof elements === 'string' && elements === 'keywords') {
                this.value.keywords = "";
            }
            else elements.forEach(el => this.value[el] = "");
        },

        customLabel({title}) {
            return title
        },
        customLabelName({name}) {
            return name
        },
    }
}
