
export default {
    props: ['src', 'placeholder'],
    data() {
        return {
            load_error: false,
            default_placeholder: '/image-placeholder.png',
        };
    },
    computed: {
        src_computed() {
            return this.load_error
                ? this.placeholder_computed
                : (this.src || this.placeholder_computed)
            ;
        },
        placeholder_computed() {
            return this.placeholder ?? this.default_placeholder;
        }
    },
    watch: {
        src(newVal) {
            this.load_error = false;
        }
    },
    methods: {
        handleError() {
            this.load_error = true;
        },
    },
}
